
import { Dialog } from "vant";
import { nextTick, reactive } from "vue";
import { storesManageService } from "@/service";
import { useRoute } from "vue-router";
import moment from "moment";
import RejectOpenShopApply from "./components/RejectOpenShopApply.vue";
export default {
  components: {
    RejectOpenShopApply
  },
  setup() {
    const route = useRoute();
    const storeInfo = reactive<any>({
      data: null,
      async getStoreInfo() {
        try {
          const { data } = await storesManageService.storesOCDetail({
            id: route.query.id,
            approval_node_lists: 1,
            opt_info: 1,
            delay_lists: 1
          });

          data.data.oc_json_arr.forEach((json: any) => {
            json.oc_json.forEach((element: any) => {
              if (element.check_type === "check_radio") {
                const find = element.option.find((item: any) => {
                  return item.key == element.result;
                });
                if (find) {
                  element.fieldValue = find.value;
                  element.isWarning = find.is_warning;
                }
              }
              if (element.check_type === "check_checkbox") {
                element.fieldValue = element.result.split(",").map((result: string) => {
                  const find = element.option.find((item: any) => {
                    return item.key == result;
                  });
                  return {
                    value: find ? find.value : "",
                    isWarning: find.is_warning
                  };
                });
              }
            });
          });
          storeInfo.data = data.data;
        } catch (error) {
          console.log(error, "==");
        }
      }
    });

    storeInfo.getStoreInfo();

    const rejectOpenShopApplyInfo = reactive<any>({
      show: false,
      option: [],
      onSubmit() {}
    });

    const optClick = async (type: string) => {
      switch (type) {
        case "2":
          Dialog.confirm({
            title: "",
            message: `确认通过"${storeInfo.data.title}"的申请吗`
          }).then(async () => {
            storesManageService.storesOCApproval({
              oc_id: route.query.id,
              approval_type: "1"
            });
            storeInfo.data = null;
            await nextTick();
            storeInfo.getStoreInfo();
          });
          break;

        case "3": {
          rejectOpenShopApplyInfo.show = true;
          rejectOpenShopApplyInfo.option = storeInfo.data.oc_json_arr.map((item: any) => {
            return {
              title: item.title,
              id: item.oc_json_id
            };
          });
          const data: any = await new Promise(resolve => {
            rejectOpenShopApplyInfo.onSubmit = (data: any) => {
              resolve(data);
            };
          });
          rejectOpenShopApplyInfo.show = false;
          storesManageService.storesOCApproval({
            oc_id: route.query.id,
            approval_type: "2",
            oc_json_ids: data.checked,
            reason: data.reason
          });
          storeInfo.data = null;
          await nextTick();
          storeInfo.getStoreInfo();
          break;
        }

        default:
          break;
      }
    };

    return {
      moment,
      storeInfo,
      optClick,
      rejectOpenShopApplyInfo
    };
  }
};
